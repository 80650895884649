import React from "react";
import Slider from "react-slick";
import "./car.css";
import { Typography } from "@mui/material";
const ScarfCall = ({ category, images }) => {
  const slidesToShow = Math.min(4, images.length);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow, // Use pre-calculated value
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: Math.min(3, images.length), // Recalculate for each breakpoint
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(2, images.length),
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: Math.min(1, images.length),
        },
      },
    ],
  };
  
  return (
    <div className="product-carousel">
     <Typography
        variant="h4"
        sx={{
          paddingTop: "1rem",
          fontWeight: "bold",
          color: "#333333",
          textAlign: "center",
          marginTop:"20px"
        }}
      >
        {category}
      </Typography>
      <Slider {...settings} className="scarf">
        {images.map((image, index) => (
          <div key={index} >
            <img
              src={image}
              alt={`${category} - ${index + 1}`}
              draggable="false"
              
              onContextMenu={(e) => e.preventDefault()}
              style={{
                userSelect: "none",
                WebkitUserDrag: "none",
                pointerEvents: "none",
                marginLeft:"10px"
              }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ScarfCall;
