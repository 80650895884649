import emailjs from "emailjs-com";
import React, { useState } from "react";
import { Box, Typography, Grid, TextField, Button } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

function Contact() {
  const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const EMAILJS_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const EMAILJS_USER_ID = process.env.REACT_APP_EMAILJS_USER_ID;
  emailjs.init(EMAILJS_USER_ID);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    body: "",
    contactNumber: "",
  });

  const [sent, setsent] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const sendEmail = async (data) => {
    setsent("Sending...");
    try {
      const message = data.body + "\n Contact Number : " + data.contactNumber;
      const emailParams = {
        to_name: "Deepak Nathwani", // Replace with the recipient's name
        from_name: data.name,
        from_email: data.email,
        subject: data.subject,
        message: message,
        customParam1: data.contactNumber, // Add customParam1 to emailParams
      };

      await emailjs.send(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, emailParams);

      setFormData({
        name: "",
        email: "",
        subject: "",
        body: "",
        contactNumber: "",
      });
      setsent("✔️ Email sent successfully ");
    } catch (error) {
      console.error("Error sending email:", error);
      setsent("Failed Try Again");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendEmail(formData);
  };

  return (
    <div>
      <div className="iframeparent">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3766.858514007902!2d73.0171890804257!3d19.24499703979808!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7bdd695ed32dd%3A0x6ef265c70efadeb5!2sDipisha%20Fashline%20Enterprises%20LLP!5e0!3m2!1sen!2sin!4v1691221252546!5m2!1sen!2sin"
          width="600"
          height="450"
          style={{ border: "0" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="iframe hero-mid"
        ></iframe>
      </div>
      <Box sx={{ paddingTop: "2rem", backgroundColor: "white" }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} sx={{ backgroundColor: "white" }}>
            <Box
              sx={{
                padding: 3,
                maxWidth: 800,
                margin: "0 1rem",
                textAlign: "left",
              }}
            >
              <Typography
                variant="h4"
                gutterBottom
                sx={{ textAlign: "center" }}
                className="cnt1"
              >
                Connect With Us
              </Typography>

              <Typography variant="h5" className="cnt">
                Our Address
              </Typography>
              <Typography variant="body1" paragraph className="abtp">
                Dipisha Fashline Enterprises LLP <br />
                Bldg I, Gala no.104, Shree Rajlaxmi Complex, Kalher, <br />
                Bhiwandi 421302, Maharashtra, India
              </Typography>

              <Typography variant="h5" gutterBottom className="cnt">
                Tel:
              </Typography>
              <Typography variant="body1" paragraph className="abtp">
                <PhoneIcon /> +91 702 145 3815
              </Typography>

              <Typography variant="h5" gutterBottom className="cnt">
                Mob:
              </Typography>
              <Typography variant="body1" paragraph className="abtp">
                <PhoneIcon /> +91 981 949 0635
              </Typography>

              <Typography variant="h5" gutterBottom className="cnt">
                Email:
              </Typography>
              <Typography variant="body1" paragraph className="abtp">
                <EmailIcon />
                <a href="mailto:dipishafashline@gmail.com">
                  {" "}
                  dipishafashline@gmail.com
                </a>
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              backgroundColor: "#112C50",
              width: "100%",
            }}
          >
            <Box
              className="form-container"
              sx={{
                padding: "2rem",
                backgroundColor: "#112C50",
                borderRadius: "8px",
                width: { xs: "100%", sm: "80%", md: "90%" },
                margin: "0 auto",
              }}
            >
              <Typography
                variant="h6"
                component="h2"
                sx={{
                  marginBottom: "1rem",
                  textAlign: "center",
                  color: "#EF6602",
                }}
                className="cnt1"
              >
                Reach Out To Us
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  textAlign: "center",
                  color: "white",
                  marginBottom: "1rem",
                }}
              >
                {sent}
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="Your Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    style: { color: "#d8e6ed" },
                    sx: {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#EF6602",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#EF6602",
                      },
                    },
                  }}
                  InputLabelProps={{
                    style: { color: "#d8e6ed" },
                  }}
                />
                <TextField
                  fullWidth
                  label="Your Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    style: { color: "#d8e6ed" },
                    sx: {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#EF6602",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#EF6602",
                      },
                    },
                  }}
                  InputLabelProps={{
                    style: { color: "#d8e6ed" },
                  }}
                />
                <TextField
                  fullWidth
                  label="Your Contact Number"
                  name="contactNumber"
                  value={formData.contactNumber}
                  onChange={handleChange}
                  required
                  margin="normal"
                  variant="outlined"
                  inputProps={{
                    pattern: "\\d{10}",
                    title: "Please enter a valid 10-digit contact number.",
                  }}
                  InputProps={{
                    style: { color: "#d8e6ed" },
                    sx: {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#EF6602",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#EF6602",
                      },
                    },
                  }}
                  InputLabelProps={{
                    style: { color: "#d8e6ed" },
                  }}
                />
                <TextField
                  fullWidth
                  label="Subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    style: { color: "#d8e6ed" },
                    sx: {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#EF6602",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#EF6602",
                      },
                    },
                  }}
                  InputLabelProps={{
                    style: { color: "#d8e6ed" },
                  }}
                />
                <TextField
                  fullWidth
                  label="Message Body"
                  name="body"
                  value={formData.body}
                  onChange={handleChange}
                  required
                  margin="normal"
                  variant="outlined"
                  multiline
                  rows={4}
                  InputProps={{
                    style: { color: "#d8e6ed" },
                    sx: {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#EF6602",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#EF6602",
                      },
                    },
                  }}
                  InputLabelProps={{
                    style: { color: "#d8e6ed" },
                  }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{
                    marginTop: "1rem",
                    backgroundColor: "#EF6602", // Custom background color
                    "&:hover": {
                      backgroundColor: "#a54701", // Darker shade on hover
                    },
                  }}
                >
                  Send Email
                </Button>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Contact;
